.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ocupa toda la pantalla */
    background-color: black; /* Fondo oscuro */
    position: relative;
    overflow: hidden; /* Oculta cualquier desbordamiento del efecto */
  }
  
  .loader-container::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(0, 255, 0, 0.2), transparent 60%);
    animation: pulseEffect 2.5s infinite;
    z-index: 0;
  }
  
  .zoom-loader {
    width: 150px; /* Tamaño inicial del logo */
    animation: zoomInOut 2s infinite ease-in-out; /* Animación del zoom */
    filter: drop-shadow(0 0 20px #00ff00); /* Glow verde */
    position: relative;
    z-index: 1; /* Asegura que el logo esté por encima del fondo */
  }
  
  @keyframes zoomInOut {
    0%, 100% {
      transform: scale(1); /* Tamaño inicial */
    }
    50% {
      transform: scale(1.2); /* Aumenta el tamaño */
    }
  }
  
  @keyframes pulseEffect {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  